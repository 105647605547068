<template>
    <div class="header p_sticky">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="logo" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li v-for="(item, index) in links" :key="index" :class="{ active: item.active }">
              <router-link :to="item.url" :class="{ active: item.url === '/intro' }">{{ item.name }}</router-link>
            </li>
          </ul>
          <Avatar style="margin-right: 37px;" />
        </div>
      </div>
    </div>
    <img class="swiper-img" style="display: block; width: 1200px; margin: 0 auto"
      :src="require('../pages/app/images/introduce.jpg')" />
    <div class="wrap">
      <div class="intro-part">
        <h2>中医临床药学服务与调剂教育平台</h2>
        <div>
          中医临床药学服务与调剂教育平台专注于中医药继续教育资源共享服务。本平台汇集了中医临床药学与中药调剂相关的专业知识、学术文章、教学视频、行业动态等内容，以冀成为中医临床药学与中药调剂服务人员的继续教育平台和学术交流园地。
        </div>
        <div>平台建设依托单位：北京中医药大学中药调剂标准化研究中心。</div>
        <div>平台建设专家支持团队：世界中医药学会联合会中药调剂专业委员会专家。</div>
        <div>平台信息技术支持单位：杭州唐古信息科技有限公司。</div>
        <div>
          本平台在建设过程中，受国家重点研发计划项目“中医国际标准研制与评价研究”（2019YFC1712000）子课题——“中医临床药学服务与调剂教育国际标准研制”（2019YFC1712002）课题经费资助。
        </div>
      </div>
      <div class="intro-part">
        <h2>中药调剂专委会</h2>
        <div>
          本平台为世界中医药学会联合会中药调剂专业委员会（简称“中药调剂专委会”）信息发布平台。中药调剂专委会由北京中医药大学中药调剂标准化研究中心，携手中国、法国、美国、日本等国家和地区中药调剂领域的领军人材共同组建。中药调剂专委会围绕“规范调剂操作、培养调剂人才、构建调剂体系”三方面开展工作，致力于培养中医临床用药标准化研究专业人才、推进《中药调剂学》课程建设和学科建设、发布行业信息、推动企业合作等事业进步，制定中药调剂行业规范，以利于中药调剂学长足进步，满足新医改和社会的需要。
        </div>
      </div>
      <div class="intro-part" style="margin-bottom: 2em;">
        <h2>合作医院</h2>
        <div>本平台与中药调剂专委会副会长医院及各地医联体医共体单位建立合作关系，汇聚多方力量，让优质教育资源普惠基层。合作医院包括以下医院：</div>
        <div>
          首都医科大学附属北京中医医院、北京大学第三医院、中国中医科学院西苑医院、成都中医药大学附属医院、中国科学院中关村医院、北京中医药大学第三附属医院、浙江省中医院、湖北省中医院、南京市中医院、甘肃省中医院、上海市中医医院、山东省中医院、广东省中医院、上海市第六人民医院、上海中医药大学附属岳阳中西医结合医院、南方医科大学中西医结合医院；山东省临沂市中医医院医联体、浙江省金华市中医医院医联体、浙江省永康市中医院医共体、浙江省仙居县中医院医共体、浙江省仙居县人民医院医共体、福建省明溪县总医院医共体、安徽省宁国市中医院医共体、安徽省濉溪县中医医院医共体、湖北省武汉市江夏区中医院医共体、山东省宁津县中医院医共体、重庆市沙坪坝中医院医共体、山西省绛县中医医院医共体、云南省勐海县中医院医共体、浙江省东阳市中医院医共体等。
        </div>
      </div>
      <div style="margin: 4rem 12rem;">
        <img class="introImg" :src="require('../pages/app/images/intro_img1.png')" />
        <img class="introImg" :src="require('../pages/app/images/intro_img2.png')" />
      </div>
      <div class="intro-part2">
        <h2>APP功能</h2>
        <div style="text-indent: 2em;">本平台配套APP“调剂教育”，具有课程学习、中医社区、知识库、医院专区四大功能版块。</div>
        <div class="flex-row justify-between">
          <div v-for="(item, index) in aims" :key="index" class="aim">
            <div class="text-center">
              <span :class="'app-aim-icon round ' + item.icon" :style="{ backgroundColor: item.bgc }"></span>
            </div>
            <h3 class="aim-name" v-text="item.name"></h3>
            <div v-html="item.intro"></div>
          </div>
        </div>
      </div>
    </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import "swiper/css/swiper.min.css";
const logo = require("../assets/logo.jpg");

@Options({
  components: {},
})
export default class Home extends Vue {
  logo = logo;

  aims = [
    {
      name: "课程学习",
      icon: "picon pi-boshimao",
      bgc: "#2782D7",
      intro:
        "提供中药临床药学服务以及中药调剂技术等相关课程视频内容，多角度、全方位培养提升药师专业水平",
    },
    {
      name: "中医社区",
      icon: "picon pi-shequ",
      bgc: "#605AB0",
      intro: "为用户提供日常交流互动的在线论坛，可以实现经验分享、问题反馈和疑问解答",
    },
    {
      name: "知识库",
      icon: "picon pi-zhishiku",
      bgc: "#CC9999",
      intro:
        "内容涵盖中药饮片调剂、中成药调剂、小包装饮片调剂、中药调剂法律法规等，满足药师日常学习需求",
    },
    {
      name: "医院专区",
      icon: "picon pi-yiyuan",
      bgc: "#2BA245",
      intro:
        "邀请医院用户加入平台并参与医院专区共建，以分享文章或视频等方式，为本院药师提供更多学习机会",
    },
  ];

  mounted() {

  }
}
</script>

<style scoped lang="less">
.part-header {
  h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .line {
    height: 2px;
    background: #ddd;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 1rem 0.5em 0;

    .line-inner {
      background: #f39801;
      height: 100%;
    }
  }
}

.intro-part {
  margin: 8rem 12rem;
  font-size: 1.4rem;
  line-height: 1.6;

  h2 {
    margin: 1.5rem 0;
  }

  div {
    text-indent: 2em;
    line-height: 1.8em;
    text-align: justify;
  }
}

.intro-part2 {
  margin: 8rem 12rem;
  font-size: 1.4rem;
  line-height: 1.6;

  h2 {
    margin: 1.5rem 0;
  }
}

.aim {
  width: 23.5%;
  color: #999;
  font-size: 1.1rem;

  .app-aim-icon {
    color: white;
    font-size: 3rem;
    height: 5.3rem;
    width: 5.3rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    line-height: 1;
  }

  .aim-name {
    font-size: 1rem;
    text-align: center;
    margin: 2rem 0;
    color: #333;
  }
}

.introImg {
  width: 100%;
  object-fit: cover;
}
</style>